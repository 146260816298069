import React from 'react';
import './App.css';

function App() {

  return (
    <div className="App">
      <div className="content">
        <h2 className="app">CLOSEAI</h2>
        <h1 className="coming"><span id="col">COMING</span></h1>

        <h1 className="coming"><span id="col">SOON</span></h1>

      </div>
    </div>
  );
}

export default App;